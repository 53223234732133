import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="Disadvantages of traditional trim" />
    <h1>Disadvantages of Traditional Methods of Attaching Molding and Trim</h1>
    <p>Conventional methods of installing molding or trim normally employ the use of either a brad nailer with appropriate brads or the old traditional method of manually hammering in adequately sized finishing nails through the front face of molding or trim. In either case the brads or finishing nails are meant to securely affix molding or trim to the wall. The only advantage of using nails and brads to attach molding is that during the initial installation phase the actual brad nailing can be done quite quickly.</p>
    <p>The main disadvantages of using the old traditional methods of installing molding and trim will include:</p>
    <ul>
      <li>a nail or brad through the front face means the nail has to be over driven into the piece and then the hole filled with filler material and then the front face of the trim needs to be re-finished. All of these actions will require that a skilled finisher will have to spend an adequate amount of time to try and hide the blemish marks left by brads and nails.</li>
      <li>trim is not easily removed when nailed to the wall and when it is removed the nails or brads either stay in the wall or stay in the trim.</li>
      <li>nails that stay in the trim need to be removed and when they do additional holes have to be repaired.</li>
      <li>trim is quite often damaged beyond repair when it is removed from the wall and this necessitates that the trim has to be replaced. In these cases, where old trim cannot be matched with new trim, whole rooms or a whole house may need all new trim.</li>
      <li>with the exception of possibly baseboards, most casing, wainscoting and crown molding is rarely removed unless major renovations are done in a building, thereby the trim is usually masked with masking tape while painting the walls.  This operation of masking and removing masking is a long and tedious job and even though the person masking the trim may think they’ve done a good job of masking the trim, paint always seems to bleed underneath the tape.  Great care must be taken when removing the masking tape as well because while removing it the old paint or wallboard can easily be ripped from the wall if one is not careful. The additional hours spent on preparing a room for painting and the hours spent removing masking tape is always factored in when painting building walls.</li>
      <li>It is found that most home developers and finishing carpenters are now adding caulking to gaps left when molding or trim is poorly attached to walls.  This relatively new finishing practice makes it even harder to remove the molding or trim and makes painting even more difficult to perform.  It is nearly impossible to maintain a distinct line between the walls and molding or trim when this type of installation is re-painted.  This practice of caulking adds significant expense to the attachment of molding and trim and this caulking would not be necessary if the molding or trim was held tighter to the wall than what nails and brads can accomplish.</li>
    </ul>
  </Layout>
)

export default SecondPage
